/* eslint import/no-cycle: 0 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import Image from 'plp/components/ProductListPage/components/ProductList/components/Product/components/Image/image';
import { ENTER_KEYCODE, SPACE_KEYCODE } from 'client-utils/keyCodes';
import { SOLD_OUT_TEXT } from 'plp/constants';
import Promotions from 'plp/components/ProductListPage/components/ProductList/components/Product/components/Promotions/promotions';
import SalePrice from 'plp/components/ProductListPage/components/ProductList/components/Product/components/SalePrice/salePrice';
import { get113TypePromotion } from 'client-utils/utilities-promotions';
import { renderFormattedPrice } from 'client-utils/utilities-price';
import { referralSourceClicked } from 'shared/actions/actions-page';
import { unescape } from 'he';
import './ProductTile.scss';
import { openProductPanel } from 'cms/actions';

const TAGS_REG_EXP = /(<([^>]+)>)/gi;

export class CarouselTile extends Component {
  componentDidMount() {
    const {
      openProductPanel,
      id: ids,
      carouselId: componentId,
      renderInDrawer,
      url,
      ppOpener,
      panelDescription,
    } = this.props;

    this.element.addEventListener('click', () => {
      referralSourceClicked('Product_Rail', 'product');
      if (!renderInDrawer) {
        openProductPanel({
          ids,
          componentId,
          ppOpener,
          panelDescription,
        });
      } else {
        window.location.href = url;
      }
    });
  }

  handleKeyDown = (event, url) => {
    if (event.which === ENTER_KEYCODE || event.which === SPACE_KEYCODE) {
      event.stopPropagation();
      window.location.href = url;
    }
  }

  filterPromotions(promotions) {
    return promotions ? partition(promotions, { showPromoPrice: true }) : [[], []];
  }

  render() {
    const {
      id = '',
      imageUrl = '',
      designer = '',
      name = '',
      url = '',
      price = {},
      promotions = [],
      displayable,
      displayOptions = {},
      inView = false,
      isLazyLoaded,
      hideStatus,
      imageRef = null,
      renderInDrawer,
    } = this.props;
    const {
      displayDesigner,
      displayDescription,
      displayPrice,
      displayPromotions,
      carouselDirection,
    } = displayOptions;
    const [
      promotionWithPrice,
      promotionWithoutPrice,
    ] = this.filterPromotions(promotions);
    const defaultImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABkAAAAABCAQAAAAs0arfAAAAGklEQVR42u3BIQEAAAACIP1/2hcmoAEAADgZGQIAAgXe4QoAAAAASUVORK5CYII=';
    const originalPrice = get(price, 'adornments[0].price', 0);
    let formattedOGPrice;
    if (originalPrice > 0) {
      formattedOGPrice = renderFormattedPrice(originalPrice, price.currencyCode);
    }
    originalPrice ? renderFormattedPrice(originalPrice) : null;
    const retailPrice = renderFormattedPrice(price.retailPrice, price.currencyCode);
    const promotionalPrice = renderFormattedPrice(price.promotionalPrice, price.currencyCode);
    const descriptionStyle = carouselDirection === 'Vertical'
      ? { paddingLeft: '8px', paddingRight: '8px' }
      : {};
    const descriptionStyleDrawer = renderInDrawer
      ? { textAlign: 'left' }
      : {};

    const shouldRenderImage = !isLazyLoaded || inView;
    const overlayClasses = classnames('product-thumbnail-list__overlay ', shouldRenderImage && 'item-fade');
    const strippedDescription = name.replace(TAGS_REG_EXP, ' ').trim();

    const renderImage = () => {
      if (isLazyLoaded) {
        return (
          inView
            ? (
              <div className="product-thumbnail-list__image-container">
                <Image src={imageUrl} alt={`${designer} ${strippedDescription}`} />
              </div>
            )
            : <img src={defaultImg} alt="" />
        );
      }

      return <Image src={imageUrl} alt={`${designer} ${strippedDescription}`} />;
    };

    const productInfoClassName = (className) => {
      return renderInDrawer ? `${className}__drawer` : className;
    };

    return (
      <button
        ref={(el) => { this.element = el; }}
        id={id}
        onKeyDown={(e) => this.handleKeyDown(e, url)}
        className={classnames('product-thumbnail-list', isLazyLoaded && 'spacing')}
      >
        <div className={classnames('product-carousel-tile', { 'product-carousel-tile__vertical': carouselDirection === 'Vertical' })}>
          <div className={classnames('product-thumbnail-list__image', hideStatus)} ref={imageRef}>
            { shouldRenderImage && renderImage() }
            { isLazyLoaded && <div className={overlayClasses} /> }
            {!displayable
              && <div className={classnames('product-thumbnail-list__image__soldout', hideStatus)}>{SOLD_OUT_TEXT}</div>
            }
          </div>
          <div className={classnames('product-thumbnail-list__description', hideStatus)} style={descriptionStyleDrawer}>
            {displayDesigner
              && (
              <div
                className={designer && designer.trim() ? productInfoClassName('product-thumbnail-list__description__designer') : productInfoClassName('no-designer')}
                style={descriptionStyle}
                aria-hidden="true"
              >
                {unescape(designer)}
              </div>
              )
            }
            {displayDescription
              && (
              <div className={productInfoClassName('product-thumbnail-list__description__name')} style={descriptionStyle} aria-hidden="true">
                {unescape(strippedDescription)}
              </div>
              )
            }
            <div className="product-thumbnail-list__description__price" style={descriptionStyle}>
              <SalePrice
                currencyCode={price.currencyCode}
                retailPrice={displayPrice && retailPrice}
                adornments={price.adornments}
                isPLP
                original={formattedOGPrice}
                isUIPRICTest
                priceAdornmentColor={`#${get113TypePromotion(promotions).templatePricingAdornColor || ''}`}
                promotionComponent={displayPromotions && !isEmpty(promotionWithPrice) ? () => (
                  <Promotions
                    promotions={promotions}
                    currencyCode={price.currencyCode}
                    promotionalPrice={promotionalPrice}
                    isPLP
                    promoColor={get113TypePromotion(promotions).thumbnailPromoColor}
                    promoText={get113TypePromotion(promotions).templateHTML}
                    isUIPRICTest
                  />
                ) : null
              }
              />
              {displayPromotions && <Promotions promotions={promotionWithoutPrice} />}
            </div>
          </div>
        </div>
      </button>
    );
  }
}

export default connect(null, { openProductPanel })(CarouselTile);
